import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CustomNavbar extends Component {
    render() {
        return (
            <nav id="fixed-top" className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg">
                <div className="container"><Link to="/"><span className="navbar-brand"><img style={{ width: 65, height: 65 }} src={require('../images/logo.png')} alt="logo" /><img style={{ width: 65, height: 65 }} src={require('../images/logo-dk.png')} alt="logo" /></span></Link><button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item"><a className="nav-link" href="#home">صفحۂ اول</a></li>
                            <li className="nav-item"><a className="nav-link" href="#features">خصوصیات</a></li>
                            {/* <li className="nav-item"><a className="nav-link" href="#faq">سوالات</a></li> */}
                        </ul>
                        <a href="./" className="get-btn">ڈاؤن لوڈ کریں</a>
                    </div>
                </div>
            </nav>
        );
    }
}

export default CustomNavbar;