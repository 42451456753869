import React, { Component } from 'react';
import Title from './Title';
import FeaturesItems from './FeaturesItems';

class Features extends Component {
    render() {
        return (
            <section className="best_screen_features_area features_area_pad" id="features">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" id="down_bg_copy_2" data-name="down / bg copy 2" className="cls-1" d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z" />
                </svg>
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -00, "y": 100}'><img className="br_shape" src={require('../images/line/f_l_01.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 200, "y": 200}'><img className="br_shape" src={require('../images/line/f_l_02.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_03.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img className="br_shape" src={require('../images/line/f_l_05.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img src={require('../images/line/f_l_06.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 100, "y": 100}'><img src={require('../images/line/f_l_07.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img src={require('../images/line/f_l_08.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": 250}'><img src={require('../images/line/f_l_09.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": 250}'><img src={require('../images/line/f_l_10.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_04.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="ایپ کی نمایاں خصوصیات"/>
                    {/* pfont="Cumque adipisci anim quisque provident posuere blandit accumsan delectus quam quos interdum sociosqu." */}
                    <div className="row ">
                        <div className="col-lg-6">
                            <div className="b_screen_img wow fadeInUp"><img src={require('../images/features/mobile-screens.png')} alt="featured" className="img-fluid"/></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row b_features_info">
                                <FeaturesItems ftitle="باآسانی مطالعہ" text="مشرب ناب ایپ کے ذریعے صارف تمام رسالوں کا باآسانی مطالعہ کر سکتے ہیں، اور اپنے پسندیدہ مضامین کو محفوظ بھی کر سکتے ہیں" name="book"/>
                                <FeaturesItems ftitle="مطالب کی تلاش" text="صارف کسی بھی مطلب کو بہت آسانی سے الفاظ اور جملے درج کر کے تلاش کر سکتے ہیں، اور اپنی دلچسپی کے موضوعات کا مطالعہ کر سکتے ہیں" name="search"/>
                                <FeaturesItems ftitle="سوال و جواب" text="صارفین اپنے سوالات بھی ایپ میں درج کر سکتے ہیں جن کے جوابات جامعہ کی ٹیم کی طرف سے انہیں دیے جائیں گے" name="pencil-alt"/>
                                <FeaturesItems ftitle="تمام مجلے" text="ایپ میں 2008 سے لے کر اب تک کے تمام مجلے موجود ہیں، جن کے ذریعے صارف خالص دینی تعلیمات سے آشنا ہو سکتے ہیں" name="envelope"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;