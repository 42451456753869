import React, { Component } from 'react';
class BannerContent extends Component {
    render(){
        var { name} = this.props;
        return(
            <React.Fragment>
                <div className="n_banner_content">
                    <h2 className="wow fadeInUp" data-wow-delay="300ms">{name}</h2>
                    <br/>
                    <br/>
                    <br/>
                    <a href="https://play.google.com/store/apps/details?id=com.azaan.mashrabenaab" className="btn btn-normal apps-button wow fadeInUp" data-wow-delay="450ms"><i className="fa fa-android"></i> <span>Get it on</span><br/> Google Play </a>
                    <a href="https://apps.apple.com/vn/app/mashrab-e-naab/id6443939739" className="btn btn-normal apps-button wow fadeInUp" data-wow-delay="450ms"><i className="fa fa-apple"></i> <span>Download on The</span><br/> App Store </a>
                    {/* <a href="https://drive.google.com/u/0/uc?id=1G-H2MKnnHZOnceAp7SUHvet74ZLrvJLt&export=download" target="_blank" rel="noopener noreferrer" className="btn btn-normal apps-button wow fadeInUp" data-wow-delay="450ms"><i className="fa fa-windows"></i> <span>Get it on</span><br/> Windows</a> */}
                </div>
            </React.Fragment>
            
        );
    }
}

export default BannerContent;