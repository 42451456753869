import React, { Component } from 'react';
import {BrowserRouter as Router } from 'react-router-dom';
import CustomNavbar from './component/CustomNavbar';
import Banner from './component/Banner/Banner';
import Features from './component/Features';
import About from './component/About';
import Footer from './component/Footer/Footer';
import $ from 'jquery';

import WOW from 'wowjs';

class App extends Component {
    componentDidMount(){
      if (typeof window !== 'undefined') {
        const wow = new WOW.WOW({
          live: false,
        })
        wow.init()
      }
        var head = $(".navbar");
        var stick = "shrink";

        $(window).scroll(function() {
            $(window).scrollTop() > 0
                ? head.addClass(stick)
                : head.removeClass(stick)
        })

        $('.navbar li a').on('click', function(event) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top-75
            }, 1500);
            event.preventDefault();
        });
    }
  render() {
    return (
      <Router>
        <div className="App" dir="rtl">
          <div className="body_wrapper">
            <CustomNavbar />
            <Banner />
            <Features />
            <About/>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
